<div *ngIf="displayed && options.length > 0" class="fixed full" [style.z-index]="z">
  <div class="overlay" (mousedown)="handleClick()"></div>

  <div
    [ngClass]="{ collapsed: !isShown }"
    class="fixed options text-sm"
    [style]="isShown ? positionStyle : positionStyle + hiddenStyle"
  >
    <div *ngFor="let o of options"
         class="flex items-center option p-2 px-3"
         (click)="handleClick(o)"
         [ngClass]="{ selected: o.isSelected }"
    >
      <svg *ngIf="o.icon" class="w-4 h-4 mr-3" [icon]="o.icon" [style.color]="o.iconColor || o.color"></svg>
      <avatar
        *ngIf="showPhotos && !o.icon && (!!o.entity || o.photoUrl)"
        [data]="getAvatarData(o)"
        [size]="photoSize"
        [borderRadius]="photoRadius"
        class="mr-2"
      ></avatar>
      <div class="overflow-hidden" [style.color]="o.color">
        <div class="display text-ellipsis whitespace-nowrap overflow-hidden" [innerHtml]="o.display"></div>
        <div *ngIf="!!o.label" class="text-xs label text-9" [innerText]="o.label"></div>
        <div *ngIf="!!o.label2" class="text-xs label text-b leading-5" [innerText]="o.label2"></div>
      </div>
    </div>
  </div>

</div>
