import { Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DDMOption, OptionsService } from './options.service';
import { BasePopUpDirective, PopUpFloat } from '@ui/base.popup.directive';

@Directive({
  selector: '[options]',
})
export class OptionsDirective extends BasePopUpDirective<DDMOption[], DDMOption | void | undefined> implements OnDestroy {

  @Input() float: PopUpFloat = 'right';
  @Input() z = 17;
  @Input() scale = 1;
  @Input() showPhotos?: boolean;
  @Input() fitWidth?: boolean;
  @Input() maxHeightRem?: number;

  private _options: DDMOption[] = [];

  @Input() set options(options: DDMOption[]) {
    if (options?.length) {
      if (!this._options?.length || JSON.stringify(options) !== JSON.stringify(this._options)) {
        this.setData(options);
        this._options = options;
      }
    }
  }

  @Output() onSelect = new EventEmitter<any>();
  @Output() onOpen = new EventEmitter<void>();
  @Output() onClose = new EventEmitter<void>();

  override getPopUpExtra(): Record<string, any> {
    return {
      fitWidth: this.fitWidth,
      showPhotos: this.showPhotos,
      maxHeightRem: this.maxHeightRem,
      scale: this.scale
    }
  }

  override onResponse(data?: DDMOption): void {
    if (data) {
      this.onSelect.emit(data);
    }
  }

  constructor(el: ElementRef, service: OptionsService) {
    super(el, service)
  }

  override async show(data: any): Promise<void> {
    this.onOpen.emit();
    await super.show(data);
    this.onClose.emit();
  }
}
