import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy } from '@angular/core';
import { DDMAction, DDMBase, DDMOption, DDMOptions, DDMSelect, OptionsService } from './options.service';
import { DomUtils } from '../../lib/dom.utils';
import { AvatarData } from '@dto/commons/avatar';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionsComponent implements OnDestroy {

  options: DDMOption[] = [];
  positionStyle = '';
  hiddenStyle = '';

  isShown = false;
  displayed = false;
  showPhotos = false;
  z = 17;

  private readonly subscription;
  private readonly updateSubscription;
  photoSize= 2.4;
  photoRadius = '50%';
  callback?: (result?: DDMOption) => void;

  constructor(
    public service: OptionsService,
    private cdr: ChangeDetectorRef
  ) {
    this.subscription = this.service.onOpen.subscribe(p => {
      this.callback?.(undefined);
      this.callback = p.callback;
      this.show(p.data)
    });
    this.updateSubscription = this.service.onUpdate.subscribe(p => {
      this.show(p.data)
    });
  }

  show(p: DDMOptions): void {
    this.options = p.data;
    this.z = p.z ?? this.z;
    this.photoSize = p.photoSize ?? this.photoSize;
    this.photoRadius = p.photoRadius ?? this.photoRadius;
    this.showPhotos = !!p.showPhotos;

    const scale = p.scale ?? 1;
    const rect = p.rect;
    const showOver = (innerHeight - p.rect.bottom) * 2 < rect.top && // below space * 2 < over space
      rect.bottom + (p.data.length + 1) * DomUtils.remToPX(3) > innerHeight;

    const xStyle = p.float === 'right'
      ? `right: ${ innerWidth - rect.right }px;`
      : `left: ${ rect.left }px;`;

    const yStyle = showOver
      ? `bottom: ${ innerHeight - rect.top + DomUtils.remToPX(.5) * scale }px;` // show over
      : `top: ${ rect.bottom + DomUtils.remToPX(.5) * scale }px;`; // show below

    const width = p.fitWidth ? `width:${ rect.width }px;` : '';

    const maxH = p.maxHeightRem ? DomUtils.remToPX(p.maxHeightRem) : Number.MAX_SAFE_INTEGER;

    const maxHeight = showOver
      ? `max-height:${ Math.min(maxH, rect.top - DomUtils.remToPX(3)) }px;`
      : `max-height:${ Math.min(maxH, innerHeight - rect.bottom - DomUtils.remToPX(3)) }px;`;

    const xSign = p.float === 'right' ? 1 : p.float === 'left' ? -1 : 0;
    const ySign = showOver ? 1 : -1;

    let [x, y, s] = [
      50 * (1 - scale * .5) * xSign,
      50 * (1 - scale * .5) * ySign,
      scale * .5
    ];

    this.hiddenStyle = `transform: translate(${ x }%, ${ y}%) scale(${ s})`;

    this.positionStyle = xStyle + yStyle + maxHeight + width;

    if (scale && scale !== 1) {
      [x, y] = [50 * (1 - scale) * xSign, 50 * (1 - scale) * ySign];
      this.positionStyle += `transform: translate(${ x }%, ${ y }%) scale(${ scale });`;
    }

    this.displayed = true;
    this.cdr.detectChanges();
    requestAnimationFrame(() => {
      this.isShown = true;
      this.cdr.markForCheck();
    });
  }

  handleClick(option?: DDMOption): void {
    if (option?.action) {
      option.action();
    }
    this.isShown = false;
    if (option && !option.action) {
      this.options.forEach(o => o.isSelected = false);
      option.isSelected = true;
    }
    this.cdr.markForCheck();
    setTimeout(() => {
      this.displayed = false;
      this.callback?.(option);
      delete this.callback;
      this.cdr.markForCheck();
    }, 150);
  }

  @HostListener('document:keydown.escape')
  hideOptions(): void {
    if (this.displayed) {
      this.handleClick();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.updateSubscription.unsubscribe();
  }

  getAvatarData(o: Partial<DDMAction & DDMSelect<any>> & DDMBase): AvatarData {
    return {
      ...o.entity,
      photoUrl: o.photoUrl
    };
  }
}
