import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptionsComponent } from './options.component';
import { OptionsDirective } from './options.directive';
import { IconDirective } from '../icon/icon.directive';
import { AvatarComponent } from '../avatar/avatar.component';

@NgModule({
  declarations: [OptionsComponent, OptionsDirective],
  imports: [CommonModule, IconDirective, AvatarComponent],
  exports: [OptionsComponent, OptionsDirective]
})
export class OptionsModule {}
